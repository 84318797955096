import moment from 'moment-timezone';
import { TicketConfig } from '@bam/sdk';

import { getTimezoneFromName } from './utils';

/**
 * Utility function to apply the event timezone on the field.
 * @param time The specified time the timezone should be applied.
 * @param timezoneName Name of the timezone.
 */
export function applyTimezone(time: Date, timezoneName: string): Date {
  const timezone = getTimezoneFromName(time, timezoneName);

  // The `Date` type is used in the SDK, but dates in javascript don't support timezones
  // Todo: Fix this when a better type is used in the SDK.
  return moment
    .parseZone(time)
    .utcOffset(timezone.timeValue, true)
    .toISOString(true) as any as Date;
}

/**
 * From the SDK, we get a Date object which represents the instance in time.
 * Unfortunately, this is then displayed in the local timezone,
 * so we have to convert it in way that keeps the local time in the original zone but is actually in the local zone.
 * Ie. 2023-11-18T11:55:00-05:00 to
 *     2023-11-18T11:55:00+01:00,
 * presuming that -05:00 is the offset in the original zone and that the browser is in +01:00
 * @param date Js Date made from the correct moment in time
 * @param originalZone The timezone of the original date, used for calculating the correct local time
 * @returns Date object which represents the same local time, but in the browser timezone
 */
export function convertDateToDisplay(date: Date, originalZone: string): Date {
  if (!date) return;

  // First we reunite the time with the timezone, to get the correct local time
  const originalMoment = moment(date).tz(originalZone);

  // We need to keep the local time (ie. 12:00), but move it to the timezone of the browser, since that is how it will be displayed.
  // Determine the local timezone name by guessing :D
  const localZone = moment.tz.guess(true);
  // Modify the moment in time to keep the local time, but change the timezone
  const localMoment = originalMoment.tz(localZone, true);

  return localMoment.toDate();
}

export function updateTicketConfigDates(
  apiTicketConfig: TicketConfig,
  originalTimezoneName: string
) {
  const ticketConfig = {
    ...apiTicketConfig,
    startSaleAt: convertDateToDisplay(
      apiTicketConfig.startSaleAt,
      originalTimezoneName
    ),
    endSaleAt: convertDateToDisplay(
      apiTicketConfig.endSaleAt,
      originalTimezoneName
    )
  };
  if (apiTicketConfig.ticketsValidFrom != null) {
    ticketConfig.ticketsValidFrom = convertDateToDisplay(
      apiTicketConfig.ticketsValidFrom,
      originalTimezoneName
    );
  }
  if (apiTicketConfig.ticketsValidTo != null) {
    ticketConfig.ticketsValidTo = convertDateToDisplay(
      apiTicketConfig.ticketsValidTo,
      originalTimezoneName
    );
  }
  return ticketConfig;
}

interface TimeInterval {
  startAt: Date;
  endAt: Date;
}

/**
 * Utility function that checks if the intervalA is contained (inclusively or exclusively) in intervalB
 * @param intervalA time interval based on start and end at provided
 * @param intervalB time interval based on start and end at provided
 * @precision unit of time representing the level of precision applied when comparing intervals - if ommited, milliseconds are used
 * @inclusive boolean value representing inclusivity of startAt and endAt values of intervalB in comparison  - if omitted, these values are excluded
 * @param timezoneName Name of the timezone.
 */
export function isInRange(
  intervalA: TimeInterval,
  intervalB: TimeInterval,
  precision: moment.unitOfTime.StartOf = undefined,
  inclusive: boolean = false
): boolean {
  const startMoment = moment(intervalA.startAt);
  const endMoment = moment(intervalA.endAt);
  const intervalIsValid = startMoment.isSameOrBefore(endMoment);
  const inclusivity = inclusive ? '[]' : '()';
  const startMomentInRange = startMoment.isBetween(
    intervalB.startAt,
    intervalB.endAt,
    precision,
    inclusivity
  );
  const endMomentInRange = endMoment.isBetween(
    intervalB.startAt,
    intervalB.endAt,
    precision,
    inclusivity
  );
  return intervalIsValid && startMomentInRange && endMomentInRange;
}
