// TODO: Add proper types
const getDefaultState = () => {
  return {
    user: {
      id: null,
      username: null,
      email: null,
      enrollmentId: null,
      verified: false,
      phoneVerified: false,
      mfaEnabled: false,
      firstName: null,
      lastName: null,
      street1: null,
      street2: null,
      city: null,
      state: null,
      country: null,
      zip: null,
      phone: null,
      dateOfBirth: null,
      gender: null,
      description: null,
      pictureUrl: null,
      permissions: {}
    }
  };
};

export const state = () => getDefaultState();

// Permission helper functions
function canGrantAccountPerms(state, org) {
  return state.user.permissions[org]?.org_level?.includes(
    'account.change_permissions'
  );
}
function canGrantEventPerms(state, org) {
  return state.user.permissions[org]?.org_level?.includes(
    'event.change_permissions'
  );
}
function canGrantBcPerms(state, org) {
  return state.user.permissions[org]?.org_level?.includes(
    'bc.change_permissions'
  );
}
function canInviteUser(state, org) {
  return state.user.permissions[org]?.org_level?.includes(
    'account.user.create'
  );
}
function canEditUser(state, org) {
  return state.user.permissions[org]?.org_level?.includes(
    'account.user.update'
  );
}
function canViewUser(state, org) {
  return state.user.permissions[org]?.org_level?.includes('account.user.view');
}
function canDeleteUser(state, org) {
  return state.user.permissions[org]?.org_level?.includes(
    'account.user.delete'
  );
}
function canListUsers(state, org) {
  return state.user.permissions[org]?.org_level?.includes('account.user.list');
}
function canCreateEvent(state, org) {
  return state.user.permissions[org]?.org_level?.includes('bc.event.create');
}
function canListEvents(state, org) {
  return state.user.permissions[org]?.org_level?.includes('event.event.list');
}
function canCreateLocation(state, org) {
  return state.user.permissions[org]?.org_level?.includes('event.event.create');
}
function canUpdateOrganizer(state, org) {
  return state.user.permissions[org]?.org_level?.includes(
    'account.organization.update'
  );
}
function canViewOrganizer(state, org) {
  return state.user.permissions[org]?.org_level?.includes(
    'account.organization.view'
  );
}
function canViewEvent(state, org) {
  return state.user.permissions[org]?.org_level?.includes('event.event.view');
}
function canUpdateEvent(state, org) {
  return state.user.permissions[org]?.org_level?.includes('event.event.update');
}

function canCreateNFT(state, org) {
  return state.user.permissions[org]?.org_level?.includes('nft.nft.create');
}

export const getters = {
  getUser(state) {
    return state.user;
  },
  canInviteUser: (state) => (org) => {
    return canInviteUser(state, org) && canGrantBcPerms(state, org);
  },
  canGiveUMRole: (state) => (org) => {
    return canGrantAccountPerms(state, org) && canGrantBcPerms(state, org);
  },
  canGiveEMRole: (state) => (org) => {
    return canGrantEventPerms(state, org) && canGrantBcPerms(state, org);
  },
  canGiveVRole: (state) => (org) => {
    return canGrantBcPerms(state, org);
  },
  canEditPermissions: (state) => (org) => {
    return canGrantAccountPerms(state, org);
  },
  canRemovePermissions: (state) => (org) => {
    return (
      canGrantAccountPerms(state, org) &&
      canGrantBcPerms(state, org) &&
      canGrantEventPerms(state, org)
    );
  },
  getProfileImage(state) {
    return state.user.pictureUrl;
  },
  getUserEmail(state) {
    return state.user.email;
  },
  getOrgs(state) {
    return (
      Object.keys(state.user.permissions).filter((org) => {
        return canListUsers(state, org);
      }) ?? []
    );
  },
  canListUsers(state, getters) {
    return getters.getOrgs.length !== 0;
  },
  /**
   * returns organizers for users who have specified permissions
   * @param {*} state vuex state
   * @param {*} permissions array of permissions
   * @returns array of organizer names
   */
  getOrgsWithPerms: (state) => (permissions) => {
    return Object.keys(state.user.permissions).filter((org) => {
      return permissions.every((perm) =>
        state.user.permissions[org]?.org_level?.includes(perm)
      );
    });
  },
  /**
   * checks if current user has all the permissions specified (one of the roles) for the specified organizer
   * @param {*} state vuex state
   * @param {String} org name of the organizer
   * @param {Array} permissions array of permissions
   * @returns {Boolean} true/false if the user has/doesn't have specified role for the specified organizer
   */
  hasRoleForOrg: (state) => (org, permissions) => {
    return permissions.every((perm) => {
      return state.user.permissions[org]?.org_level?.includes(perm);
    });
  },
  canAccessDashboard: (state, getters) => (mainOrganizer, org) => {
    return (
      getters.canAccessMyEvents(org) ||
      getters.canAccessNFTCollectibles(org) ||
      getters.canAccessLocation(org) ||
      getters.canAccessTransactionRules(org) ||
      getters.canAccessOrganizer(org) ||
      getters.canAccessUsers(org) ||
      getters.canAccessManageAccounts(mainOrganizer)
    );
  },
  canImpersonate: (state) => (org) => {
    return state.user.permissions[org]?.org_level?.includes(
      'account.admin.impersonate'
    );
  },
  canImpersonateAndListUsers: (state) => (org) => {
    return state.user.permissions[org]?.org_level?.includes(
      'account.admin.impersonate',
      'account.user.list'
    );
  },
  canInviteOrganizers: (state) => (org) => {
    return state.user.permissions[org]?.org_level?.includes(
      'account.organization.view',
      'account.organization.update',
      'account.organization.list',
      'account.organization.create',
      'account.organization.change_permissions'
    );
  },
  canUpdateOrganizer: (state) => (org) => {
    return state.user.permissions[org]?.org_level?.includes(
      'account.organization.update'
    );
  },
  canResendEmail: (state) => (org) => {
    // Currently this endpoint uses the same permission as event creation
    return canCreateEvent(state, org);
  },
  canRevokeCertificate: (state) => (org) => {
    return state.user.permissions[org]?.org_level?.includes(
      'account.user.delete'
    );
  },
  canAccessMyEvents: (state) => (org) => {
    return canListEvents(state, org);
  },
  canAccessNFTCollectibles: (state) => (org) => {
    return canCreateNFT(state, org);
  },
  canAccessLocation: (state) => (org) => {
    return canCreateLocation(state, org);
  },
  canAccessTransactionRules: (state) => (org) => {
    return canUpdateOrganizer(state, org);
  },
  canAccessOrganizer: (state) => (org) => {
    return canUpdateOrganizer(state, org);
  },
  canAccessUsers: (state) => (org) => {
    return canListUsers(state, org);
  },
  canAccessEventInfo: (state) => (org) => {
    return canCreateEvent(state, org);
  },
  canAccessReporting: (state) => (org) => {
    return canViewOrganizer(state, org);
  },
  canAccessSendTickets: (state) => (org) => {
    return canCreateEvent(state, org);
  },
  canAccessViewOrders: (state) => (org) => {
    return canViewEvent(state, org);
  },
  canAccessSendEmails: (state) => (org) => {
    return canViewEvent(state, org);
  },
  canAccessManageAccounts: (state) => (org) => {
    return canListUsers(state, org);
  },
  canUpdateEmails: (state) => (org) => {
    return canUpdateEvent(state, org);
  },
  canEditUsers: (state) => (org) => {
    return canEditUser(state, org);
  },
  canSendEventNotifications: (state) => (org) => {
    return canViewEvent(state, org);
  },
  canSendGlobalNotifications: (state) => (org) => {
    return canEditUser(state, org);
  },
  canSendNewsletter: (state) => (mainOrganizer) => {
    return canEditUser(state, mainOrganizer);
  },
  canDeleteNewsletter: (state) => (mainOrganizer) => {
    return canDeleteUser(state, mainOrganizer);
  },
  canViewNewsletter: (state) => (mainOrganizer) => {
    return canViewUser(state, mainOrganizer);
  },
  canConvertOrder: (state) => (org) => {
    return canCreateEvent(state, org);
  },
  getDashboardUrl: (state, getters) => (org, mainOrganizer) => {
    if (getters.canAccessMyEvents(org)) {
      return '/my-events';
    }
    if (getters.canAccessNFTCollectibles(org)) {
      return '/collections';
    }
    if (getters.canAccessLocation(org)) {
      return '/location';
    }
    if (getters.canAccessReporting(org)) {
      return '/reporting';
    }
    if (getters.canAccessOrganizer(org)) {
      return '/organizer/list';
    }
    if (getters.canAccessUsers(org)) {
      return '/users';
    }
    if (getters.canAccessManageAccounts(org, mainOrganizer)) {
      return '/manage-accounts';
    }
    return '/';
  }
};

export const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  resetUser(state) {
    Object.assign(state, getDefaultState());
  },
  // NOTE: This mutation updates all user object fields except permissions
  // - once the user endpoints return permissions setUser will be committed instead
  updateUserData(state, userData) {
    state.user = { ...state.user, ...userData };
  }
};

export const actions: any = {
  setUser({ commit }, payload) {
    commit('setUser', payload);
  }
};
